<template>
  <div>
    <b-row>
      <b-col
        v-for="key in Object.keys(modules)"
        :key="key"
        sm="4"
      >
        <item
          :module-key="key"
          :module-label="getModuleLabel(key)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Item from './components/Item.vue'

export default {
  name: 'List',
  components: {
    Item,
  },
  data: () => ({
    modules: {
      // The official list of the modules ;)
      mailboxModule: 'Moduł poczty e-mail',
      whatsappModule: 'WhatsappModule',
      offerModule: 'OfferModule',
      clientMobileModule: 'ClientMobileModule',
      pollModule: 'PollModule',
      agreementModule: 'AgreementModule',
      applicationModule: 'ApplicationModule',
      paymentModule: 'PaymentModule',
      orderModule: 'OrderModule',
      projectModule: 'ProjectModule',
      loyaltyModule: 'LoyaltyModule',
      marketingModule: 'MarketingModule',
      campaignModule: 'CampaignModule',
      automationModule: 'AutomationModule',
      newsModule: 'NewsModule',
      chatGptModule: 'ChatGptModule',
      imageRecognitionModule: 'ImageRecognitionModule',
      complaintModule: 'ComplaintModule',
      bookingModule: 'BookingModule',
      clientBrowserModule: 'clientBrowserModule',
      callModule: 'callModule',
      shortcutModule: 'shortcutModule',
    },
  }),
  mounted() {
    if (!this.checkRequiredPermissions([this.$roles.FULL_ACCESS])) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  methods: {
    getModuleLabel(key) {
      return this.$i18n.t(this.modules[key])
    },
  },
}
</script>

<style scoped>

</style>

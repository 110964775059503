<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <h4>
        {{ moduleLabel }}
      </h4>
      <b-form-checkbox
        v-model="settings[moduleKey]"
        switch
        variant="primary"
        :disabled="loading"
        @change="toggleModule"
      />
    </div>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Item',
  props: {
    moduleKey: {
      required: true,
      default: '',
      type: String,
    },
    moduleLabel: {
      required: true,
      default: '',
      type: String,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      settings: 'system/getSettings',
    }),
  },
  methods: {
    async toggleModule(event) {
      this.loading = true

      try {
        await axiosIns.patch('1/settings/global', { [this.moduleKey]: event })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
